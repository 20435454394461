import React from "react";
import AboutPlatform from "./AboutPlatform";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { HomeJson } from "./HomeJson";
import Header from "globals/components/Header";
import HomeSlider from "./HomeSlider";
import TechSuite from "./TechSuite";
import useScrollToTopOnMount from "globals/utils/useScrollToTopOnMount";
import style from "./scss/home.module.scss";
import ContactForDemo from "globals/components/Contact/ContactForDemo";

// Assuming the images are placed in your assets folder
import whoWeAreImage from "assets/images/home/who-we-are-image.png";
import whatWeSolveImage from "assets/images/home/what-we-solve-image.png";
import icon1 from "assets/images/home/solveicon1.png";
import icon2 from "assets/images/home/solveicon2.png";
import icon3 from "assets/images/home/solveicon3.png";
import icon4 from "assets/images/home/solveicon4.png";
import icon5 from "assets/images/home/solveicon5.png";
// Images for the "What We Offer" cards
import offerImage1 from "assets/images/home/offerImage1.png";
import offerImage2 from "assets/images/home/offerImage2.png";
import offerImage3 from "assets/images/home/offerImage3.png";
import offerImage4 from "assets/images/home/offerImage4.png";
import offerImage5 from "assets/images/home/offerImage5.png";
// Images for the "Why HRLytics" cards
import whyImage1 from "assets/images/home/whyImage1.png";
import whyImage2 from "assets/images/home/whyImage2.png";
import whyImage3 from "assets/images/home/whyImage3.png";
import whyImage4 from "assets/images/home/whyImage4.png";

const Home = (props) => {
  const isTabletView = window.innerWidth < 992;

  useScrollToTopOnMount();

  // Data for the "What We Solve" points with icons
  const whatWeSolvePoints = [
    {
      text: "Stagnant HR models stuck in compliance and transactions.",
      icon: icon1,
    },
    {
      text: "Fresh talent pools lacking structure, assessment, and upskilling.",
      icon: icon2,
    },
    {
      text: "L&D inefficiencies with low adoption and misaligned learning paths.",
      icon: icon3,
    },
    {
      text: "Disconnected leadership in HR, with limited transformation vision.",
      icon: icon4,
    },
    {
      text: "Poor retention due to lack of capability mapping and growth pathways.",
      icon: icon5,
    },
  ];

  // Data for the "What We Offer" cards
  const whatWeOfferCards = [
    {
      image: offerImage1,
      header: "Workforce Diagnostics",
      content:
        "In-depth, AI-driven assessments of current HR and L&D ecosystems—spotting gaps before they become crises.",
    },
    {
      image: offerImage2,
      header: "Strategy to Execution Consulting",
      content:
        "Design and deployment of full-stack HR and skilling transformations—from process redesign to tech platform rollouts.",
    },
    {
      image: offerImage3,
      header: "Intelligent Learning Ecosystems",
      content:
        "AI-integrated LXP & capability frameworks personalized to every learner's journey, from fresher to future leader.",
    },
    {
      image: offerImage4,
      header: "Leadership Re-architecture",
      content:
        "Advisory services to help redesign HR and L&D leadership with research-backed, transformation-oriented profiles.",
    },
    {
      image: offerImage5,
      header: "Talent Supply Chain Optimization",
      content:
        "Redefine partnerships with colleges/universities, build rigorous fresher programs, and embed performance-first culture.",
    },
  ];

  // Data for the "Why HRLytics" cards
  const whyHRLyticsCards = [
    {
      image: whyImage1,
      header: "Tech meets Human Depth",
      content:
        "We blend the analytical power of AI with the empathy and nuance of human expertise.",
    },
    {
      image: whyImage2,
      header: "Cross-Domain Expertise",
      content:
        "From IT to BFSI, manufacturing to healthcare – we tailor solutions for every industry.",
    },
    {
      image: whyImage3,
      header: "End-to-End Execution",
      content:
        "We don’t just strategise. We deploy, train, measure, and continuously evolve with you.",
    },
    {
      image: whyImage4,
      header: "Higher-Order Thinking",
      content:
        "Our philosophy is to build scalable, intelligent ecosystems for the future.",
    },
  ];

  return (
    <>
      <Header />
      <div className={style.noborder}>
        <div className={style.homeouter}>
          <div className={style.homeimage}>
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={9}
                widescreen={9}
              >
                <div className={style.homeleft}>
                  <span className={style.heroTagline}>TRANSFORM PEOPLE</span>
                  <h1 className={style.heroTitle}>TRANSCEND PERFORMANCE</h1>
                  <h1 className={style.heroTitle}>MAXIMIZE HUMAN POTENTIAL</h1>
                  <h2 className={style.heroSubtitle}>
                    HRLytics | The Future of Human Capital Starts Here.
                  </h2>
                  <div className={style.contactBox}>
                    <ContactForDemo />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column largeScreen={7} widescreen={7}>
                {!isTabletView && <HomeSlider HomeJson={HomeJson} />}
              </Grid.Column>
            </Grid>
            <div className={style.heroDescriptionSection}>
              <p className={style.heroDescription}>
                In a World Racing toward AI, Digital Reinvention, and Business
                Disruption - Companies Don’t Just Need People.
              </p>
              <div className={style.heroSubDescription}>
                <span>They Need Intelligent Ecosystems.</span>
                <div className={style.circleDecor}>
                  <div className={style.square}></div>
                </div>
                <span>They Need Transformation Partners.</span>
                <div className={style.circleDecor}>
                  <div className={style.square}></div>
                </div>
                <span>They need HRLytics.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Who We Are Section */}
      <div className={style.whoWeAreSection}>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            largeScreen={8}
            widescreen={8}
          >
            <div className={style.whoWeAreContent}>
              <h2 className={style.whoWeAreTitle}>Who We Are</h2>
              <div className={style.whoWeAreMobileImage}>
                <img src={whoWeAreImage} alt="Who We Are" />
              </div>
              <div className={style.whoWeAreTextContent}>
                <h3 className={style.whoWeAreSubtitle}>
                  HRLytics is not just a consulting firm.
                </h3>
                <p className={style.whoWeAreDescription}>
                  We are transformation architects—engineered to reimagine how
                  your organization attracts, develops, retains, and elevates
                  human potential.
                </p>
                <p className={style.whoWeAreDescription}>
                  With a unique blend of diagnostics, strategic consulting, tech
                  enablement, and execution, we partner with organizations to
                  rebuild HR and Skilling ecosystems from the ground up.
                </p>
                <p className={style.whoWeAreDescription}>
                  Rooted in AI, behavioral science, workforce analytics, and
                  human expertise, we are the catalyst for capability evolution.
                </p>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            largeScreen={8}
            widescreen={8}
          >
            <div className={style.whoWeAreImage}>
              <img src={whoWeAreImage} alt="Who We Are" />
            </div>
          </Grid.Column>
        </Grid>
      </div>

      {/* What We Solve Section */}
      <div className={style.whatWeSolveSection}>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            largeScreen={8}
            widescreen={8}
          >
            <div className={style.whatWeSolveContent}>
              <h2 className={style.whatWeSolveTitle}>What We Solve</h2>
              <div className={style.whatWeSolvePoints}>
                {whatWeSolvePoints.map((point, index) => (
                  <div key={index} className={style.whatWeSolvePoint}>
                    <img
                      src={point.icon}
                      alt={`Icon ${index + 1}`}
                      className={style.pointIcon}
                    />
                    <span className={style.pointText}>{point.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            largeScreen={8}
            widescreen={8}
          >
            <div className={style.whatWeSolveImage}>
              <img src={whatWeSolveImage} alt="What We Solve" />
            </div>
          </Grid.Column>
        </Grid>
      </div>

      {/* What We Offer Section */}
      <div className={style.whatWeOfferSection}>
        <div className={style.whatWeOfferContent}>
          <h2 className={style.whatWeOfferTitle}>What We Offer</h2>
          <div className={style.whatWeOfferCards}>
            {whatWeOfferCards.map((card, index) => (
              <div key={index} className={style.offerCard}>
                <img
                  src={card.image}
                  alt={card.header}
                  className={style.offerCardImage}
                />
                <h3 className={style.offerCardHeader}>{card.header}</h3>
                <p className={style.offerCardContent}>{card.content}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Why HRLytics Section */}
      <div className={style.whyHRLyticsSection}>
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <div className={style.whyHRLyticsTitleWrapper}>
              <h2 className={style.whyHRLyticsTitle}>Why HRLytics</h2>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={12}
            largeScreen={12}
            widescreen={12}
          >
            <div className={style.whyHRLyticsCards}>
              {whyHRLyticsCards.map((card, index) => (
                <div key={index} className={style.whyCard}>
                  <img
                    src={card.image}
                    alt={card.header}
                    className={style.whyCardImage}
                  />
                  <h3 className={style.whyCardHeader}>{card.header}</h3>
                  <p className={style.whyCardContent}>{card.content}</p>
                </div>
              ))}
            </div>
          </Grid.Column>
        </Grid>
      </div>

      {/* New Section: Make Them Smarter */}
      <div className={style.makeSmarterSection}>
        <div className={style.makeSmarterContent}>
          <h2 className={style.makeSmarterText}>
            Your Business Is Only as Smart as Your People.<br />
            Let’s Make Them Smarter. Stronger. Future-Proof.
          </h2>
          <div className={style.makeSmarterContact}>
            <ContactForDemo />
          </div>
        </div>
      </div>

      <AboutPlatform />
      <TechSuite HomeJson={HomeJson} />
    </>
  );
};

export default withTranslation("translations")(Home);